const LOCALE = {
  'VALIDATION': {
    maxDiscount: "Discount should be less than total price",
    minTotalPrice: "Total Price must be greater than discount",
    errRequired: "Field can't be empty",
    attachmentRequired: 'Attachment required',
    errAlreadyAdded: '%{displayLabel} already added.',
    errSelect: 'Please select %{displayLabel}.',
    errChoose: 'Please choose %{displayLabel}.',
    errValid: 'Please enter a valid %{displayLabel}.',
    minLength: '%{displayLabel} should be at least %{minChar} chars long.',
    maxLength: '%{displayLabel} cannot be more than %{maxChar} chars.',
    equalTo: '%{displayLabel} length should be equal to %{equals}',
    uniqField: '%{displayLabel} already in use. Try another.',
    confirmationMatch: "Password didn't match",
    lettersOnly: 'Please enter only letters.',
    alphanumericOnly: 'Only Alphabets and Numbers allowed.',
    digitsOnly: 'Please enter only digits.',
    letterAndSpaceOnly: "Letters and spaces only please",
    lettersSpacesOrSpecialCharactersOnly: "Letters, spaces or special characters only please",
    noSpace: "Please don't enter space",
    firstName: 'first name',
    lastName: 'last name',
    middleName: 'middle name',
    email: 'email',
    email_address: 'email address',
    password: 'password',
    atLeast6Character: 'at least 6 characters.',
    passwordComplexity: 'Password should contain atleast one lowercase character, one uppercase character, one digit and one special character',
    current_password_is_invalid: 'Current password is invalid',
    nameFormat: "%{displayLabel} format is incorrect",
    phoneNumber: "Phone Number",
    currentPassword: "Current password",
    passwordConfirmation: "password confirmation",
    errTermsChecked: 'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
    errTermsOfServiceChecked: 'Please indicate that you have read and agree to the Terms of Services',
    avatar_error: "Please upload a png, jpg or pdf file",
    document_error: "Please upload a png, jpg, pdf or xls file",
    only_document_error: "Please upload pdf or xls file",
    select_notifications: 'Please select notifications',
    select_file: 'Please select a valid file to upload',
    projectName: 'Project Name',
    project_description: 'Project Description',
    contactName: 'Contact Name',
    subject: ' Subject',
    description: ' Description',
    officeName: 'Office Name',
    contactName: 'Contact Name',
    contactAddress: 'Contact Address',
    nameOfDocument: 'Name of Document',
    nameOfAttachment: 'Name of Attachment',
    description: 'Description',
    postal_code: 'Postal Code',
    validEmail: 'Please enter a valid email address.',
    invalid_phone_number: 'Phone number is invalid',
    invalid_country_code: 'Country code is invalid',
    nameMaxLength: '%{displayLabel} is too long, a maximum of %{maxChar} characters allowed',
    nameMinLength: '%{displayLabel} is too short, minimum %{minChar} characters required',
    name: 'Name',
    state: 'State',
    city: 'City',
    select_state: 'Select State',
    select_city: 'Select City',
    select_project_unit: 'Select Project Unit',
    select_project: 'Select Project',
    passwordMinLength: 'Password should have atleast 8 characters',
    passwordMaxLength: 'Password should be less then 20 characters ',
    serviceName: 'Service name',
    only_spaces_in_name: 'Name can not contain only spaces',
    image_size: 'Warning! The image resolution is low and it could stretch while displaying. For better resolution you can upload image with better resolution',
    personaNameFormat: 'Enter valid name',
    invalidCountryCode: 'Invalid country code',
    invalidUrlFormat: 'Invalid URL format'
  },
  'BUTTON':{
    next: 'Next',
    previous: 'Previous',
    remove: 'Remove'
  }
};

export { LOCALE };
