let httpRequest = function() {
  // constructor
}
httpRequest.prototype = {
  config: function(endPoint, httpType, show_loader, processParams = false, options={}) {
    return  {
      url: endPoint,
      type: httpType,
      cache: false,
      contentType: false,
      processData: processParams,
      data: options,
      async: false,
      beforeSend: function (xhr) {
        if(show_loader)
          $('#loader').removeClass("display-none")
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      },
      complete: function (xhr) {
        $('#loader').addClass("display-none")
      }
    };
  },

  post: function (endPoint, postData={}, options={}, show_loader = true) {
    let config = this.config(endPoint, 'POST', show_loader);
    config.data = postData;
    config = Object.assign(config, options);
    return $.ajax(config);
  },
  delete: function (endPoint, postData={}, options={}, show_loader = true) {
    let config = this.config(endPoint, 'DELETE', show_loader);
    config.data = postData;
    config = Object.assign(config, options);
    return $.ajax(config);
  },
  get: function(endPoint, options={}, show_loader = true) {
    let config = this.config(endPoint, 'GET', show_loader, true, options);
    config = Object.assign(config, options);
    return $.ajax(config);
  },
  put: function (endPoint, postData={}, options={}, show_loader = true) {
    let config = this.config(endPoint, 'PUT', show_loader);
    config.data = postData;
    config = Object.assign(config, options);
    return $.ajax(config);
  },
  patch: function (endPoint, postData={}, options={}, show_loader = true) {
    let config = this.config(endPoint, 'PATCH', show_loader);
    config.data = postData;
    config = Object.assign(config, options);
    return $.ajax(config);
  }
};

export default new httpRequest;
