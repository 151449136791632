const LOCALE = {
  'VALIDATION': {
    maxDiscount: "ส่วนลดควรน้อยกว่าราคารวม",
    minTotalPrice: "ราคารวมต้องมากกว่าส่วนลด",
    errRequired: "ไม่สามารถปล่อยว่างได้",
    attachmentRequired: "กรุณาแนบไฟล์",
    errAlreadyAdded: "%{displayLabel}เพิ่มแล้ว",
    errSelect: "โปรดเลือก %{displayLabel}",
    errChoose: "โปรดเลือก %{displayLabel}",
    errValid: "โปรดป้อนข้อมูล %{displayLabel}",
    minLength: "%{displayLabel} ควรมีความยาวอย่างน้อย %{minChar} ตัวอักษร",
    maxLength: "%{displayLabel} ต้องมีความยาวไม่เกิน %{maxChar} ตัวอักษร",
    equalTo: "%{displayLabel} ควรมีความยาวเท่ากับ %{equals}",
    uniqField: "%{displayLabel} ถูกใช้แล้ว ลองใหม่",
    confirmationMatch: "รหัสผ่านไม่ตรงกัน",
    lettersOnly: "โปรดป้อนเฉพาะตัวอักษร",
    alphanumericOnly: "เฉพาะตัวอักษรและตัวเลขเท่านั้น",
    digitsOnly: "โปรดป้อนเฉพาะตัวเลข",
    letterAndSpaceOnly: "โปรดป้อนเฉพาะตัวอักษรและเว้นวรรค",
    lettersSpacesOrSpecialCharactersOnly: "โปรดป้อนเฉพาะตัวอักษร เว้นวรรค หรืออักขระพิเศษเท่านั้น",
    noSpace: "กรุณาอย่าเว้นวรรค",
    firstName: "ชื่อ",
    lastName: "นามสกุล",
    middleName: "ชื่อกลาง",
    email: "อีเมลล์",
    email_address: "ที่อยู่อีเมลล์",
    password: "รหัสผ่าน",
    atLeast6Character: "อย่างน้อย 6 ตัวอักษร",
    passwordComplexity: "รหัสผ่านควรมีอักษรตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว ตัวพิมพ์ใหญ่หนึ่งตัว และอักขระพิเศษหนึ่งตัว",
    current_password_is_invalid: "รหัสผ่านปัจจุบันไม่ถูกต้อง",
    nameFormat: "%{displayLabel}รูปแบบไม่ถูกต้อง",
    phoneNumber: "หมายเลขโทรศัพท์",
    currentPassword: "รหัสผ่านปัจจุบัน",
    passwordConfirmation: "ยืนยันรหัสผ่าน",
    errTermsChecked: "โปรดระบุว่าคุณได้อ่านและยอมรับข้อกำหนดและเงื่อนไขและนโยบายความเป็นส่วนตัว",
    errTermsOfServiceChecked: "โปรดระบุว่าคุณได้อ่านและยอมรับข้อกำหนดในการให้บริการ",
    avatar_error: "โปรดอัปโหลดไฟล์ png jpg หรือ pdf",
    document_error: "โปรดอัปโหลดไฟล์ png jpg หรือ xls",
    only_document_error: "โปรดอัปโหลดไฟล์ pdf หรือ xls",
    select_notifications: "โปรดเลือกการแจ้งเตือน",
    select_file: "โปรดเลือกไฟล์ที่ถูกต้องเพื่ออัปโหลด",
    projectName: "ชื่อโครงการ",
    project_description: "คำอธิบายโครงการ",
    contactName: "ชื่อผู้ติดต่อ",
    subject: "หัวข้อ",
    description: "คำอธิบาย",
    officeName: "ชื่อสำนักงาน",
    contactName: "ชื่อผู้ติดต่อ",
    contactAddress: "ที่อยู่สำหรับติดต่อ",
    nameOfDocument: "ชื่อเอกสาร",
    nameOfAttachment: "ชื่อเอกสารแนบ",
    description: "คำอธิบาย",
    postal_code: "รหัสไปรษณีย์",
    validEmail: "กรุณากรอกที่อยู่อีเมลล์ที่ถูกต้อง",
    invalid_phone_number: "หมายเลขโทรศัพท์ไม่ถูกต้อง",
    invalid_country_code: "รหัสประเทศไม่ถูกต้อง",
    nameMaxLength: "%{displayLabel} ยาวเกินไป สูงสุดไม่เกิน %{maxChar} ตัวอักษร",
    nameMinLength: "%{displayLabel} สั้นเกินไป ขั้นต่ำ %{minChar} ตัวอักษร",
    name: "ชื่อ",
    state: "จังหวัด",
    city: "อำเภอ",
    select_state: "เลือกจังหวัด",
    select_city: "เลือกอำเภอ",
    select_project_unit: "เลือกยูนิตโครงการ",
    select_project: "เลือกโครงการ",
    passwordMinLength: "รหัสผ่านควรมีอย่างน้อย 8 ตัวอักษร",
    passwordMaxLength: "รหัสผ่านควรน้อยกว่า 20 ตัวอักษร",
    serviceName: "ชื่อบริการ",
    only_spaces_in_name: "ชื่อไม่สามารถป้อนแค่เว้นวรรคได้",
    image_size: "คำเตือน! ภาพที่มีความละเอียดต่ำ อาจแสดงภาพบิดเบี้ยวได้ เพื่อการแสดงภาพที่มีความละเอียดมากขึ้นคุณสามารถอัปโหลดรูปภาพที่มีความละเอียดสูงกว่า",
    personaNameFormat: "ป้อนชื่อที่ถูกต้อง",
    invalidCountryCode: "รหัสประเทศไม่ถูกต้อง",
    invalidUrlFormat: "รูปแบบ URL ไม่ถูกต้อง",
  },
  'BUTTON':{
    next: 'ถัดไป',
    previous: 'ก่อนหน้า',
    remove: 'เอาออก'
  }
};

export { LOCALE };
