import  I18x  from '../util/i18x';
import  httpRequest  from '../util/httpRequest';
const localeMsg = I18x.moduleItems('VALIDATION');

termsValidation();

// Terms validation
function termsValidation(){
  $("#terms_form").validate({
    rules: {
      "terms": {
        required: true
      }
    },
    messages: {
      "terms": {
        required: I18x.T(localeMsg.errTermsOfServiceChecked)
      }    },
    // submitHandler: function(form) {
    //   //TODO: submit_button.disable(form);
    // },
    errorElement: "em",
    errorPlacement: function ( error, element ) {
      error.addClass( "help-block" );
      if ( element.prop( "type" ) === "checkbox" ) {
        error.insertAfter( element.closest( ".checkbox" ) );
      } else {
        error.insertAfter( element );
      }
    },
    highlight: function ( element, errorClass, validClass ) {
      $( element ).addClass( "has-error" ).removeClass( "has-success" );
      $( element ).nextAll("#correct:first").addClass('display-none')
      $( element ).nextAll("#wrong:first").removeClass('display-none')
    },
    unhighlight: function (element, errorClass, validClass) {
      $( element ).addClass( "has-success" ).removeClass( "has-error" );
      $( element ).nextAll("#wrong:first").addClass('display-none')
      $( element ).nextAll("#correct:first").removeClass('display-none')
    }
  } );
}
